import React, { useState } from "react"
import ModalVideo from "react-modal-video"
import { graphql, Link } from "gatsby"
import emailjs from "emailjs-com"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import Header from "../components/Header"
import AccordionGreen from "../components/AccordionGreen"
import AccordionGreen2 from "../components/AccordionGreen2"
import Footer from "../components/Footer/Footer"
import Button from "../components/Button"
import Content from "../components/Content"
import DatasheetIcon from "../assets/images/datasheetIcon.png"
import ReactMarkdown from "react-markdown"
import { Modal } from "react-bootstrap"
import { openPopupWidget, InlineWidget } from "react-calendly"
import Seo from "../components/SEO"
import CookieConsent from "react-cookie-consent"

const Home = ({ data }) => {
  const [email, setEmail] = useState("")
  const [name, setName] = useState("")
  const [companyName, setCompanyName] = useState("")
  const [isOpen, setOpen] = useState(false)
  const [message, setMessage] = useState("")
  const {
    allContentfulLandingPageMain,
    allContentfulSimplifyYourOversight,
    allContentfulToggleWithImagesSimplifyYourOversight,
    allContentfulWhyYouShouldTestDriveXiir,
    allContentfulFrequentlyAskedQuestions,
    allContentfulContentArea,
    allContentfulServices,
  } = data
  const download = () => {
    window.open(allContentfulLandingPageMain.nodes[0].datasheetUrl)
  }
  const [visible, setVisible] = useState(false)
  const [confirmationVisible, confirmationSetVisible] = useState(false)
  const [contactVisible, contactSetVisible] = useState(false)
  const handleSubmit = e => {
    e.preventDefault()
    emailjs
      .sendForm(
        "service_qkbep48",
        "template_vo1uex8",
        e.target,
        "user_H1giHzeT00K7WW4tInQOn"
      )
      .then(
        result => {
          console.log(result)
        },
        error => {
          console.log(error.text)
        }
      )
    emailjs
      .sendForm(
        "service_qkbep48",
        "template_8hke6d4",
        e.target,
        "user_H1giHzeT00K7WW4tInQOn"
      )
      .then(
        result => {
          console.log(result)
        },
        error => {
          console.log(error.text)
        }
      )
    handleClose()
    handleShowConfirmation()
    setEmail("")
    setName("")
    setMessage("")
    setCompanyName("")
  }

  const handleShow = () => contactSetVisible(true)
  const handleClose = () => contactSetVisible(false)
  const handleShowConfirmation = () => confirmationSetVisible(true)
  const handleCloseConfirmation = () => confirmationSetVisible(false)

  const handleSubmitEmail = e => {
    e.preventDefault()
    if (e.target["user_email"].value.length > 1) {
      handleShow()
    }
  }
  return (
    <div>
      <Seo />
      <Header />
      <CookieConsent
        enableDeclineButton
        location="bottom"
        //buttonText="Accept"
        declineButtonText="Decline"
        buttonStyle={{ marginRight: "100px" }}
        cookieName="gatsby-gdpr-google-analytics"
      >
        This site uses cookies to enhance user experience. If you would like,
        you can opt out by clicking "Decline".
      </CookieConsent>
      <section className="banner-section container">
        <div className="img-col">
          <img
            src={allContentfulLandingPageMain.nodes[0].image.file.url}
            alt={allContentfulLandingPageMain.nodes[0].image.title}
            className="img"
          />
        </div>
        <div className="d-flex justify-content-center">
          <div className="col-sm-12 col-md-10 col-lg-10 text-col text-center">
            <h1 style={{ fontWeight: "bolder", fontSize: "2.5rem" }}>
              {allContentfulLandingPageMain.nodes[0].mainHeadingOne}
            </h1>
            <h1 className="my-4">
              {allContentfulLandingPageMain.nodes[0].mainHeadingTwo}
            </h1>
            <h3>{allContentfulLandingPageMain.nodes[0].sideHeading}</h3>
            {/* NEW ADDITON!! */}{" "}
            {/* <ModalVideo
              channel="custom"
              isOpen={isOpen}
              autoplay 
              url={allContentfulLandingPageMain.nodes[0].videoLink}
              onClose={() => setOpen(false)}
            /> */}
            {/* <div className="container text-center section-padding-1">
              <div>
                <img
                  src={
                    allContentfulLandingPageMain.nodes[0].playVideoIcon.file.url
                  }
                  alt="Play Video Icon"
                  height={70}
                  width={70}
                  id="sample-use-casesImg"
                  onClick={() => setOpen(true)}
                  style={{ cursor: "pointer" }}
                />
              </div>
              <span
                id="sample-use-cases2"
                onClick={() => setOpen(true)}
                style={{ cursor: "pointer" }}
              >
                {allContentfulLandingPageMain.nodes[0].playVideoText} &nbsp;
              </span>
            </div>
            <div className="btn-div">
              <Link to="/test-drive">
                <Button
                  text={allContentfulLandingPageMain.nodes[0].buttonText}
                  className="btn-purple bigger-btn"
                />
                </Link>
            </div>*/}
          </div>*
        </div>
      </section> 

      <Content
        main1={allContentfulContentArea.nodes[0].textMain1.textMain1}
        main2={allContentfulContentArea.nodes[0].textMain2.textMain2}
        side1={allContentfulContentArea.nodes[0].textSide1.textSide1}
        side2={allContentfulContentArea.nodes[0].textSide2.textSide2}
        className="gr-bg"
      />
      <section className="d-flex section-padding-2">
        <div className="container d-flex flex-column">
          <div className="row align-items-center justify-content-center content-area">
            {/* <div className="col-sm-12 col-md-6 col-lg-6 text-col">
              <h1>{allContentfulSimplifyYourOversight.nodes[0].heading}</h1>
              <br />
              <h3>
                {documentToReactComponents(
                  JSON.parse(
                    allContentfulSimplifyYourOversight.nodes[0].descriptionOne
                      .raw
                  )
                )}
              </h3>
              <h3>
                {documentToReactComponents(
                  JSON.parse(
                    allContentfulSimplifyYourOversight.nodes[0].descriptionTwo
                      .raw
                  )
                )}
              </h3>
            </div> */}
            <div className="col-sm-12 col-md-6 col-lg-6 img-col">
              <img
                src={allContentfulSimplifyYourOversight.nodes[0].image.file.url}
                alt={allContentfulSimplifyYourOversight.nodes[0].heading}
                className="img"
              />
            </div>
          </div>
        </div>
      </section>

      <div
        className="container text-center section-padding-2"
        id="landing-description"
      >
        <h2 className="up-margin">
          {documentToReactComponents(
            JSON.parse(allContentfulServices.nodes[0].description.raw)
          )}
        </h2>
      </div>
      <section className="section-padding">
        <div className="container">
          <div>
            <div className="row">
              <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                {allContentfulToggleWithImagesSimplifyYourOversight.nodes.map(
                  (v, i) => (
                    <AccordionGreen2
                      title={v.tittle}
                      content={v.content.content}
                      img={v.image.file.url}
                      key={i}
                    />
                  )
                )}
              </div>
            </div>
          </div>
          <center>
            <div className="btn-accod-div">
              <Link to="/solution">
                <Button
                  text="explore further"
                  className="btn-purple bigger-btn"
                />
              </Link>
            </div>
          </center>
        </div>
      </section>

      <section className="xiir-benefits" style={{ backgroundColor: "#243671" }}>
        <div
          className="container landing-why"
          style={{ paddingTop: "30px", paddingBottom: "30px" }}
        >
          <h1>{allContentfulWhyYouShouldTestDriveXiir.nodes[0].title}</h1>
        </div>
        <div className="container div-para landing-why">
          <h4 style={{ color: "#fff" }}>
            <ReactMarkdown
              children={
                allContentfulWhyYouShouldTestDriveXiir.nodes[0].description
                  .description
              }
            />
          </h4>
        </div>
        {/* </section> */}

        {/* <section className=" info-boxes"> */}
        <div className="container info-boxes">
          <div className="d-flex info-boxes-div">
            <div>
              <img
                src={
                  allContentfulWhyYouShouldTestDriveXiir.nodes[0].boxOneImg.file
                    .url
                }
                alt={
                  allContentfulWhyYouShouldTestDriveXiir.nodes[0].boxOneTitle
                }
              />
              <h4>
                {allContentfulWhyYouShouldTestDriveXiir.nodes[0].boxOneTitle}
              </h4>
              <p>
                {
                  allContentfulWhyYouShouldTestDriveXiir.nodes[0]
                    .boxOneDescription
                }
              </p>
            </div>
            <div>
              <img
                src={
                  allContentfulWhyYouShouldTestDriveXiir.nodes[0].boxTwoImg.file
                    .url
                }
                alt={
                  allContentfulWhyYouShouldTestDriveXiir.nodes[0].boxTwoTitle
                }
              />
              <h4>
                {allContentfulWhyYouShouldTestDriveXiir.nodes[0].boxTwoTitle}
              </h4>
              <p>
                {
                  allContentfulWhyYouShouldTestDriveXiir.nodes[0]
                    .boxTwoDescription
                }
              </p>
            </div>
            <div>
              <img
                src={
                  allContentfulWhyYouShouldTestDriveXiir.nodes[0].boxThreeImg
                    .file.url
                }
                alt={
                  allContentfulWhyYouShouldTestDriveXiir.nodes[0].boxThreeTitle
                }
              />
              <h4>
                {allContentfulWhyYouShouldTestDriveXiir.nodes[0].boxThreeTitle}
              </h4>
              <p>
                {
                  allContentfulWhyYouShouldTestDriveXiir.nodes[0]
                    .boxThreeDescription
                }
              </p>
            </div>
          </div>
        </div>
      </section>

      <div
        className="text-center"
        style={{ paddingTop: "50px", paddingBottom: "60px" }}
      >
        <div style={{ paddingBottom: "20px" }}>
          <div className="our-media" style={{ marginBottom: "30px" }}>
            <h1>{allContentfulLandingPageMain.nodes[0].buttonsHeading}</h1>
          </div>
          <div className="btn-div">
            <Link to="/test-drive">
              <Button
                text={allContentfulLandingPageMain.nodes[0].buttonText}
                className="btn-purple bigger-btn"
              />
            </Link>
          </div>
        </div>

        <div style={{ paddingBottom: "20px" }}>
          <button
            type="button"
            onClick={() => setVisible(true)}
            className="btn btn-purple bigger-btn"
          >
            {allContentfulLandingPageMain.nodes[0].buttonTextTwo}
          </button>
        </div>

        {/* <div style={{ paddingBottom: "20px" }}>
          <Link to="/custom-quote">
            <Button
              text={allContentfulLandingPageMain.nodes[0].buttonTextThree}
              className="btn-purple bigger-btn"
            />
          </Link>
        </div> */}

        <center>
          <section
            class="flexbox"
            style={{
              width: "15%",
              display: "table",
            }}
          >
            <div
              className="form-control"
              style={{
                display: "table-cell",
                width: "100%",
                paddingLeft: "0%",
                marginLeft: "0%",
                paddingRight: "0%",
                marginRight: "0%",
                marginTop: "0%",
                paddingTop: "0%",
                backgroundColor: "#e9ecef",
              }}
            >
              <div
                className="input-group"
                style={{
                  width: "100%",
                  paddingLeft: "0%",
                  marginLeft: "0%",
                  paddingRight: "0%",
                  marginRight: "0%",
                  backgroundColor: "#e9ecef",
                }}
              >
                <form
                  onSubmit={handleSubmitEmail}
                  style={{
                    width: "100%",
                  }}
                >
                  <label className="sr-only">EMAIL</label>
                  <input
                    style={{
                      width: "100%",
                      border: "none",
                      borderRadius: "0px",
                      marginBottom: "7px",
                    }}
                    placeholder={
                      allContentfulLandingPageMain.nodes[0].contactEmailText
                    }
                    className="form-control"
                    value={email}
                    type="email"
                    name="user_email"
                    onChange={e => setEmail(e.currentTarget.value)}
                  />
                  <Button
                    style={{
                      zIndex: "0",
                      width: "auto",
                    }}
                    className="btn-purple bigger-btn"
                    type="submit"
                    text={allContentfulLandingPageMain.nodes[0].buttonTextFour}
                  ></Button>
                </form>
              </div>
            </div>
          </section>
        </center>
      </div>

      <Modal
        style={{ marginTop: "10%", height: "100%" }}
        show={contactVisible}
        onHide={() => contactSetVisible(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title style={{ color: "#243671" }}>
            {allContentfulLandingPageMain.nodes[0].contactPopupTitle}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <center>
            <form onSubmit={handleSubmit}>
              <div className="form-group">
                <label className="sr-only">Email</label>
                <input
                  style={{
                    width: "80%",
                    marginTop: "4%",
                    backgroundColor: "white",
                    borderColor: "#f0f3f7",
                  }}
                  placeholder={email}
                  className="form-control"
                  value={email}
                  type="email"
                  name="user_email"
                  disabled
                />
              </div>
              <div className="form-group">
                <label className="sr-only">Full name</label>
                <input
                  style={{
                    width: "80%",
                    marginTop: "4%",
                    borderColor: "#b0b0b0",
                  }}
                  placeholder={
                    allContentfulLandingPageMain.nodes[0].contactPopupName
                  }
                  value={name}
                  type="text"
                  name="user_name"
                  onChange={e => setName(e.currentTarget.value)}
                />
              </div>
              <div className="form-group">
                <label className="sr-only">Company name</label>
                <input
                  style={{
                    width: "80%",
                    marginTop: "2%",
                    borderColor: "#b0b0b0",
                  }}
                  placeholder={
                    allContentfulLandingPageMain.nodes[0].contactPopupCompany
                  }
                  value={companyName}
                  type="companyName"
                  name="company_name"
                  onChange={e => setCompanyName(e.currentTarget.value)}
                />
              </div>
              <div className="form-group">
                <label className="sr-only">Message</label>
                <textarea
                  style={{
                    width: "80%",
                    marginTop: "4%",
                    borderRadius: "6px",
                    borderColor: "#b0b0b0",
                  }}
                  placeholder={
                    "  " +
                    allContentfulLandingPageMain.nodes[0].contactPopupMessage
                  }
                  value={message}
                  type="email"
                  name="user_message"
                  onChange={e => setMessage(e.currentTarget.value)}
                />
              </div>
              <div className="form-group">
                <label className="sr-only">Page</label>
                <input value="Landing Page" type="hidden" name="page" />
              </div>
              <button className="btn btn-purple" type="submit">
                {allContentfulLandingPageMain.nodes[0].contactPopupButton}
              </button>
            </form>
          </center>
        </Modal.Body>
      </Modal>

      <Modal
        style={{ marginTop: "15%", height: "100%" }}
        show={confirmationVisible}
        onHide={() => confirmationSetVisible(false)}
      >
        <Modal.Body
          style={{
            fontSize: "25px",
            backgroundColor: "#3e812c",
            color: "white",
          }}
        >
          {allContentfulLandingPageMain.nodes[0].contactConfirmationPopup}
          <button
            onClick={handleCloseConfirmation}
            class="close"
            aria-label="Close"
            type="submit"
            style={{ opacity: "1" }}
          >
            <span
              style={{ fontSize: "40px", color: "white", opacity: "1" }}
              aria-hidden="true"
            >
              &times;
            </span>
          </button>
        </Modal.Body>
      </Modal>

      {/* </Link> */}
      {/* <CustomButton url={"https://calendly.com/workeye"}/> */}
      {/* <CalendlyButton /> */}
      <Modal show={visible} onHide={() => setVisible(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Book a Demo</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <InlineWidget url="https://calendly.com/workeye/personal-demo" />
        </Modal.Body>
      </Modal>

      <section className="section-padding d-flex">
        <div className="container">
          <div className="faq">
            <h1>frequently asked questions</h1>
          </div>
          <div>
            <div className="row">
              <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                <div>
                  {allContentfulFrequentlyAskedQuestions.nodes
                    .slice(0)
                    .reverse()
                    .map((v, i) => (
                      <AccordionGreen
                        title={v.title}
                        content={documentToReactComponents(
                          JSON.parse(v.description.raw)
                        )}
                        img={v.image ? v.image.file.url : null}
                        key={i}
                        section="faq"
                      />
                    ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="download-box section-padding text-center">
        <div className="container">
          <div className="our-media">
            <h1>some items for you</h1>
          </div>
          <center>
            <div className="box">
              <div className=" datasheet-down">
                {/* <form onSubmit={handleSubmit}> */}
                <div className="input">
                  {/*<input
                    placeholder="PLEASE ENTER YOUR EMAIL"
                    value={email}
                    type="email"
                    name="user_email"
                    onChange={e => setEmail(e.currentTarget.value)}
                  />*/}
                  <img
                    src={DatasheetIcon}
                    id="datasheetIcon"
                    alt="Datasheet Icon"
                    height={100}
                    style={{ marginTop: "20px" }}
                  />
                </div>
                <br />
                <div>
                  <button
                    className="btn btn-purple bigger-btn"
                    onClick={download}
                    type="submit"
                  >
                    Download Workeye Datasheet
                  </button>
                </div>
                {/* </form> */}
              </div>
            </div>
          </center>
        </div>
      </section>
      {/*} <section className="section-padding">
        <div className="container youtube-channel">
          <div>
            {/* <ReactPlayer
              width="100%"
              height="600px"
              url={allContentfulLandingPageMain.nodes[0].homepageVideo}
            /*}>
            {/*<iframe
              src={allContentfulLandingPageMain.nodes[0].homepageVideo}
              width="100%"
              height="500px"
              allow="autoplay"
            ></iframe>
            {console.log(allContentfulLandingPageMain.nodes[0].homepageVideo)}
          </div>
        </div>
      </section> 
    */}
      <div className="">
        <Footer />
      </div>
    </div>
  )
}

export default Home

export const query = graphql`
  query {
    allContentfulLandingPageMain {
      nodes {
        buttonText
        sideHeading
        mainHeadingOne
        mainHeadingTwo
        image {
          file {
            url
          }
          title
        }
        contactEmailText
        homepageVideo
        buttonsHeading
        buttonTextTwo
        buttonTextThree
        buttonTextFour
        contactPopupTitle
        contactPopupName
        contactPopupCompany
        contactPopupMessage
        contactConfirmationPopup
        contactPopupButton
        datasheetUrl
        playVideoText
        playVideoIcon {
          file {
            url
          }
        }
        videoLink
      }
    }

    allContentfulSimplifyYourOversight {
      nodes {
        descriptionOne {
          raw
        }
        descriptionTwo {
          raw
        }
        heading
        image {
          file {
            url
          }
        }
      }
    }

    allContentfulFrequentlyAskedQuestions(
      sort: { fields: createdAt, order: DESC }
    ) {
      nodes {
        title
        description {
          raw
        }
        image {
          file {
            url
          }
        }
      }
    }

    allContentfulToggleWithImagesSimplifyYourOversight(
      sort: { fields: createdAt }
    ) {
      nodes {
        tittle
        image {
          file {
            url
          }
        }
        content {
          content
        }
      }
    }

    allContentfulWhyYouShouldTestDriveXiir {
      nodes {
        boxOneDescription
        boxOneTitle
        boxTwoTitle
        boxTwoDescription
        boxThreeTitle
        title
        description {
          description
        }
        boxOneImg {
          file {
            url
          }
        }
        boxThreeDescription
        boxThreeImg {
          file {
            url
          }
        }
        boxTwoImg {
          file {
            url
          }
        }
      }
    }
    allContentfulContentArea(sort: { fields: createdAt }) {
      nodes {
        textMain1 {
          textMain1
        }
        textMain2 {
          textMain2
        }
        textSide1 {
          textSide1
        }
        textSide2 {
          textSide2
        }
      }
    }
    allContentfulServices {
      nodes {
        description {
          raw
        }
      }
    }
  }
`
